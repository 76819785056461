@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: white;
  font-size: 16px;
}

body {
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:not(code) {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.switch-wrapper {
  position: relative;
  height: 100%;
}

.switch-wrapper > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
